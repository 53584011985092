export default {
  name: "SecuritySetting",
  data: function data() {
    return {
      email: '',
      // 邮箱
      phone: '',
      // 手机号
      // 弹框
      securityDialogTitle: '',
      modifyData: {
        email: '',
        oldPass: '',
        newPass: '',
        reNewPass: '',
        phone: ''
      },
      lablePos: 'right',
      securityDialogVisible: false,
      nowDialog: '',
      imgSrc: '',
      dialogWidth: 0
    };
  },
  created: function created() {
    this.setDialogWidth();
  },
  mounted: function mounted() {
    var _this = this;
    this.initInfo();
    //监听窗口宽度
    window.onresize = function () {
      return function () {
        _this.setDialogWidth();
      }();
    };
  },
  methods: {
    // 初始化用户基本信息
    initInfo: function initInfo() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: "getInfo",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this2.phone = res.data.mobile;
            _this2.email = res.data.email;
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    setFun: function setFun(num) {
      if (num === 1) {
        this.securityDialogTitle = '修改登录密码';
      } else if (num === 2) {
        this.securityDialogTitle = '设置手机';
      } else {
        this.securityDialogTitle = '绑定邮箱';
      }
      this.securityDialogVisible = true;
      this.setDialogWidth();
      this.nowDialog = num;
    },
    setDialogWidth: function setDialogWidth() {
      var val = parseInt(document.body.clientWidth);
      var def = 1100; //宽度最小为800,可修改
      //窗口宽度小于默认宽度时，将弹框看度设置为50%,可修改
      if (val < def) {
        this.dialogWidth = '500px';
      } else {
        //窗口宽度大于默认宽度1200时，将弹框设置为400宽度,可修改
        this.dialogWidth = '40%';
      }
    },
    // 绑定邮箱
    confirmFun: function confirmFun() {
      var _this3 = this;
      if (this.nowDialog === 1) {
        if (!this.modifyData.newPass || !this.modifyData.oldPass || !this.modifyData.reNewPass) {
          this.$toast.error('请输入密码');
          return;
        }
        if (this.modifyData.newPass !== this.modifyData.reNewPass) {
          this.$toast.error('新密码与确认密码不一致');
          return;
        }
        // 修改密码
        this.service.axiosUtils({
          requestName: "editCountPassword",
          data: {
            oldPass: this.modifyData.oldPass,
            newPass: this.modifyData.newPass
          },
          sucFn: function sucFn(res) {
            if (res.status === 1) {
              _this3.$toast.success('您重置了自己的密码，请重新登录');
              _this3.service.axiosUtils({
                requestName: 'Logout',
                data: {},
                sucFn: function sucFn(res) {
                  if (res.status === 1) {
                    _this3.Ls.removeToken('nof_token');
                    _this3.Ls.removeToken('nof_userInfo');
                    _this3.$router.push({
                      path: '/login'
                    });
                  } else {
                    _this3.$toast.error(res.message);
                  }
                }
              });
            } else {
              _this3.$toast.error(res.message);
            }
          }
        });
      } else if (this.nowDialog === 2) {
        // 设置手机号
        if (!this.modifyData.phone) return;
        this.service.axiosUtils({
          requestName: "editMobile",
          data: {
            mobile: this.modifyData.phone
          },
          sucFn: function sucFn(res) {
            if (res.status === 1) {
              _this3.$toast.success('您重置了自己的手机号，请重新登录');
              _this3.service.axiosUtils({
                requestName: 'Logout',
                data: {},
                sucFn: function sucFn(res) {
                  if (res.status === 1) {
                    _this3.Ls.removeToken('nof_token');
                    _this3.Ls.removeToken('nof_userInfo');
                    _this3.$router.push({
                      path: '/login'
                    });
                  } else {
                    _this3.$toast.error(res.message);
                  }
                }
              });
            } else {
              _this3.$toast.error(res.message);
            }
          }
        });
      } else {
        // editEmail
        if (this.modifyData.email) {
          this.service.axiosUtils({
            requestName: "editEmail",
            data: {
              email: this.modifyData.email
            },
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                // this.$toast.success(res.message)
                _this3.email = _this3.modifyData.email;
                _this3.$store.commit('setUserItem', {
                  type: 'email',
                  data: _this3.modifyData.email
                });
                _this3.securityDialogVisible = false;
                _this3.modifyData.email = '';
              } else {
                _this3.$toast.error(res.message);
              }
            }
          });
        }
      }
    },
    cancelDialog: function cancelDialog() {
      this.securityDialogVisible = false;
      this.modifyData.email = '';
    }
  }
};